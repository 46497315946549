import React, { useState } from "react";
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import ContactHeader from "components/Contact-header/contact-header";
import SContactForm from "components/s-contact-form/s-contact-form";
import ContactFromDate from "data/sections/form-info.json";
import DarkTheme from "layouts/Dark";
import SEO from "components/seo/seo";
import LoadingScreen from "components/Loading-Screen";

const Contact = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const [theme, setTheme] = useState("Dark");

  React.useEffect(() => {
    // document.querySelector("body").classList.add("contact-page");

    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });

    var storage = localStorage.getItem("theme");

    if (storage && storage != "") {
      let bodyEl = document.querySelector("body");
      bodyEl.classList.add("themeL");
      setTheme(storage);
    }

    return () => {
      // document.querySelector("body").classList.remove("contact-page");
    };
  }, [navbarRef]);

  const themeModeChange = (themeMode) => {
    let bodyEl = document.querySelector("body");
    if (themeMode == "Dark") {
      setTheme("themeL");
      bodyEl.classList.add("themeL");
      localStorage.setItem("theme", "themeL");
    } else {
      setTheme("Dark");
      bodyEl.classList.remove("themeL");
      localStorage.removeItem("theme");
    }
  };

  return (
    <DarkTheme>
      <LoadingScreen/>
      <Navbar
        nr={navbarRef}
        lr={logoRef}
        theme={theme}
        themeModeChange={themeModeChange}
      />
      <ContactHeader />
      <section className="contact section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="form md-mb50">
                <h4 className="fw-700 color-font mb-50">Get In Touch.</h4>
                <SContactForm hidePaddingAndText={true} />
              </div>{" "}
              {/* Closing div for the form */}
            </div>
            <div className="col-lg-5 offset-lg-1">
              <div className="cont-info">
                <h4 className="fw-700 color-font mb-50">Contact Info.</h4>
                <h3 className="wow" data-splitting>
                  {ContactFromDate.title}
                </h3>
                <div className="item mb-40">
                  <h5>
                    <a href="#0">{ContactFromDate.email}</a>
                  </h5>
                  <h5>{ContactFromDate.phone}</h5>
                </div>
                <h3 className="wow" data-splitting>
                  Visit Us.
                </h3>
                <div className="item">
                  <h6>
                    {ContactFromDate.location.first}
                    <br />
                    {ContactFromDate.location.second}
                  </h6>
                </div>
                <div className="social mt-50">
                  <a
                    href="https://g.page/evolvan"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-map-marker"></i>
                  </a>
                  <a href="tel:+918360801493">
                    <i className="fa fa-mobile"></i>
                  </a>
                  <a href="mailto:info@evolvan.com">
                    <i className="fa fa-envelope"></i>
                  </a>
                  <a
                    href="https://fb.com/evolvan"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    href="https://twitter.com/evolvan"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    href="https://linkedin.com/company/evolvan"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="map" id="ieatmaps">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13698.28417188046!2d75.8037788!3d30.8706826!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x10c1f3325159090f!2sEvolvan%20Info%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1670493952854!5m2!1sen!2sin"
          width="600"
          height="450"
             
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  const liveUrl = typeof window !== "undefined" ? window.location.href : "";
  return (
    <>
      <SEO
        canonical={liveUrl}
        title="Contact Evolvan | Connect with Our Expert Team for Digital Solutions"
        description="Contact  with Evolvan specialists for personalized digital solutions. Reach out to us with a message, and we're here to assist you in achieving online success."
      />
    </>
  );
};

export default Contact;
